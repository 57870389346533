import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const Index = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		ReactGA.set({ page: pathname });
		ReactGA.pageview(pathname);
	}, [pathname]);

	return null;
};

export default Index;
