import Translations from "translations";
import Routes from "./Routes";
import "./index.scss";

const Index = () => {
	return (
		<>
			<Translations />
			<Routes />
		</>
	);
};

export default Index;
