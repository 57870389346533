//https://www.i18next.com/
//https://www.i18next.com/overview/first-setup-help
import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { _useCookie } from "./custom_hooks/_useCookie";
import enTranslation from "translations/en/index.json";
import plTranslation from "translations/pl/index.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  pl: {
    translation: plTranslation,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: process.env.NODE_ENV === "development",
  });

export const setLang = (lang) => {
  const { setCookie } = _useCookie();
  setCookie("default_language", lang);
  i18n.changeLanguage(lang);
};

export const getLang = () => {
  return i18n.language;
};

const Index = () => {
  return <></>;
};

export default Index;
