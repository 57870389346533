import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loading from "pages/Loading";
import ErrorBoundary from "./ErrorBoundary.js";
import ScrollToHash from "./ScrollToHash.js";
import ScrollToTop from "./ScrollToTop.js";
import Tracker from "./Tracker.js";

const MainRoutes = React.lazy(() => import("./Main"));

const Index = () => {
	return (
		<ErrorBoundary>
			<BrowserRouter basename="/">
				<Tracker />
				<Suspense fallback={<Loading />}>
					<Routes>
						<Route path="*" element={<ScrollToTop />} />
					</Routes>
					<Routes>
						<Route path="" element={<Navigate to="/en" />} />
						<Route path="/en/*" element={<MainRoutes lang="en" />} />
						<Route path="/pl/*" element={<MainRoutes lang="pl" />} />
						<Route path="*" element={<Navigate to="" />} />
					</Routes>
					<Routes>
						<Route path="*" element={<ScrollToHash />} />
					</Routes>
				</Suspense>
			</BrowserRouter>
		</ErrorBoundary>
	);
};

export default Index;
