import { useTranslation } from "react-i18next";

import "./index.scss";

const Index = () => {
  const { t } = useTranslation();
  return <div className="loading-div">{t("loading.title")}</div>;
};

export default Index;
